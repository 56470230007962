@mixin provider-assignment-theme($theme) {

  .provider-assignment {
    .assessment {
      .assesment-config {
        background-color: map-get($toolbar-color, 500);
      }
    }
  }
  
}
