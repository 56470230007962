@mixin assessment-all-theme($theme) {
  .all-assessments {

    .month-wrapper {
      .month {
        background-color: $grey1;
      }

      .selected-month {
        background-color: map-get($main-theme-background-color, 500);
        box-shadow: 1px 3px 4px -2px $grey1;
      }
    }

    .assessment-content {

      .add-assessment {
        color: map-get($custom-table-header-color, 500);
      }

      .point {

        &.completeness-0 {
          border: 2px solid map-get($main-theme-color, 500);
        }

        &.completeness-20 {
          background-color: map-get($main-theme-color, 100);
        }

        &.completeness-40 {
          background-color: map-get($main-theme-color, 200);
        }

        &.completeness-60 {
          background-color: map-get($main-theme-color, 300);
        }

        &.completeness-80 {
          background-color: map-get($main-theme-color, 400);
        }

        &.completeness-100 {
          background-color: map-get($main-theme-color, 500);
        }
      }

      .assessment-info-wrapper {
        color: $grey2;
        box-shadow: 0 0 9px 0 $grey1;

        & .assessment-header-wrap {
          background-color: map-get($main-theme-background-color, 500);
        }
      }
    }
  }
}
