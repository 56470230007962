@mixin assessment-detail-card-theme($theme) {
  .assessment-info {
    border-bottom: 1px solid map-get($custom-table-border-color, 500);
    .assessment-title {
      color: map-get($main-theme-color, 500);
    }

    .assessment-sub-title {
      color: map-get($fuse-black, 500);
    }

    .assessment-date {
      color: map-get($custom-table-header-color, 500);
    }

    .completeness-wrapper {
      .completeness-count {
        border: 1px solid map-get($custom-table-border-color, 500);
      }
    }
  }
}
