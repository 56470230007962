@mixin exercise-program-weeks-overview-theme($theme) {
  .weeks-wrapper {
    .week {
      .week-title-wrapper {
        background-color: map-get($fuse-white, 500);
      }

      .days-wrapper {
        .day {
          .day-title {
            .day-status {
              &.completed {
                background-color: map-get($success-color, 500);
              }
              &.skipped {
                background-color: map-get($mark-color, 500);
              }
            }
          }

          .exercises-wrapper {
            .exercise {
              .exercise-options {
                .section {
                  input {
                    border: 1px solid lightgray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
