@mixin details-toolbar-theme($theme) {
  
  .details-toolbar {
    background-color: map-get($toolbar-color, 500);
    
    .refresh-button {
      color: map-get($main-theme-color, 500);
    }
  }
}
