// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

// @import '@progress/kendo-theme-default/scss/all.scss';
// Shared Application styles

.submit-button {
  height: 40px;
  width: 126px;
  margin: 16px auto !important;
  display: block !important;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0.5);
  border-radius: 4px !important;
  color: map-get($text-color-against-main-color, 500) !important;

  &.pulse-animation {
    animation: pulse 1.5s infinite;
    animation-delay: 4s;
    -webkit-animation-delay: 4s;

    &:hover {
      animation: none;
    }
  }
}

.mat-icon-wrapper {
  padding: 0 5px !important;
  min-width: 24px !important;
}

.spl-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px !important;
  width: fit-content !important;
  background: map-get($main-theme-color, 500) !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  color: map-get($text-color-against-main-color, 500) !important;
  text-transform: uppercase;
  border-radius: 2px !important;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  mat-icon {
    transform: scale(0.9);
  }

  &.center {
    display: flex;
    margin: auto;
  }

  &.mat-button-disabled {
    opacity: 0.3 !important;
  }

  &.spl-add {
    background: map-get($panel-color, 500) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  }

  &.spl-cancel {
    padding: 4px 8px !important;
    background: map-get($panel-color, 500) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  }

  &.spl-delete {
    background: map-get($toolbar-color, 500) !important;
    color: map-get($panel-color, 500) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  }

  &.spl-clear {
    background: map-get($panel-color, 500) !important;
  }

  mat-spinner {
    display: inline-flex;
    margin-left: 7px;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: map-get($text-color-against-main-color, 500) !important;
  }
}

.spl-outline-button {
  @extend .spl-button;
  background: none !important;
  border: 1px solid map-get($main-theme-color, 500) !important;
  color: map-get($fuse-black, 500) !important;
  box-shadow: none !important;
}

.spl-text-button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  width: fit-content;
  color: map-get($main-theme-color, 500);
  cursor: pointer;
}

.spl-content-wrapper {
  padding: 48px;
  max-width: 1440px;
  width: 100%;
}

.spl-select {
  width: 150px;
  height: 30px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: map-get($fuse-black, 500);

  border: 1px solid map-get($main-theme-color, 500);
  border-radius: 4px;

  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-start: 8px;
  -moz-padding-start: 8px;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  background-image: url(~src/assets/images/icons/select-arrow.svg);
  background-position: center right;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spl-select:disabled {
  opacity: 0.5 !important;
}

.solo-column {
  max-width: 480px;
  width: 100%;
  padding-top: 48px;
}

.solo-full-column {
  max-width: 1440px;
  width: 100%;
  padding: 48px;
}

@include media-breakpoint('xs') {
  .spl-content-wrapper {
    padding: 20px;
  }

  .solo-column,
  .solo-full-column {
    padding: 20px !important;
  }
}

.spl-scroll-container {
  overflow-y: auto;
}

@keyframes pulse {
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(map-get($main-theme-color, 500), 0);
    box-shadow: 0 0 0 20px rgba(map-get($main-theme-color, 500), 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0);
    box-shadow: 0 0 0 0 rgba(map-get($main-theme-color, 500), 0);
  }
}

* {
  font-family: Poppins, sans-serif !important;
}

.spl-mat-menu-header {
  padding: 5px 16px;
  font-weight: bold;
}

.fuse-sidebar-overlay {
  background-color: initial !important;
}

body.theme-default
  .mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background,
body.theme-default .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: map-get($main-theme-color, 500);
}

.dialog-content-wrapper {
  display: flex;
  flex-direction: column;

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.mat-snack-bar-container {
  &.custom-snackbar {
    &.info {
      color: map-get($text-color-against-main-color, 500) !important;
      background-color: map-get($main-theme-color, 500) !important;
    }
  }
}

.custom-readonly {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.display-none {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.kendo-dropdowntree-item-disable {
  opacity: 0.5;
}

.pie-chart-wrapper {
  .chart-legend .legend-labels {
    background: none !important;
  }

  .chart-legend .legend-label-text {
    color: map-get($fuse-black, 500);
  }

  .chart-legend .legend-wrap {
    min-width: 100px;
  }
}

kendo-multiselect.details-grid__input {
  height: 32px !important;

  .k-input-inner {
    width: 0 !important;
    padding: 0 !important;
    flex: 0 !important;
  }

  kendo-searchbar {
    width: 0 !important;
    height: 0 !important;
  }

  .k-clear-value {
    display: none !important;
  }
}

.spl-page-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);

  .spl-content-host-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 25px;

    .spl-content-host {
      display: contents;
      .spl-grid {
        height: 100%;
      }
    }
  }
}

.custom-grid {
  width: 100%;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid map-get($custom-table-border-color, 500);
  border-right: 1px solid map-get($custom-table-border-color, 500);

  .header-wrapper {
    display: flex;

    .header {
      font-weight: 600;
      padding: 20px;
      flex: 1 1 100%;
      border-width: 1px 0 0 1px;
      border-style: solid;
      color: map-get($custom-table-header-color, 500);
      border-color: map-get($custom-table-border-color, 500);
      background-color: map-get($toolbar-color, 500);
    }
  }

  .row {
    display: flex;

    .cell {
      padding: 20px;
      flex: 1 1 100%;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: map-get($custom-table-border-color, 500);

      &.selectable:hover {
        box-shadow: inset 0 0 0 2px map-get($main-theme-color, 500);
        cursor: pointer;
      }

      &.selected {
        box-shadow: inset 0 0 0 2px map-get($main-theme-color, 500);
        background-color: map-get($light-main-theme-color, 500);
      }

      &.group {
        font-size: 16px;
        font-weight: 600;
        padding: 10px 20px;
      }

      &.element,
      &.group {
        background-color: map-get($toolbar-color, 500);
      }

      &.changed {
        box-shadow: inset 0 0 0 2px map-get($main-theme-color, 500);
      }
    }
  }
}

.k-selection {
  .k-handle {
    display: none;
  }
}
.k-chart-tooltip-wrapper {
  pointer-events: none;
}

.k-pdf-export {
  .k-icon {
    display: none;
  }
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  background: initial !important;
}

.markedRow {
  background-color: map-get($main-theme-color, 50) !important;
}

.readonly-block {
  pointer-events: none;
}

.new-clients-chart-tooltip-wrapper {
  min-width: 130px;

  .category {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  .series-item {
    display: flex;
    align-items: center;
    margin-top: 5px;

    .item-color {
      width: 15px;
      height: 3px;
      margin-right: 5px;
    }

    .series-title {
      display: flex;
      min-width: 100px;
    }
  }
}

.filter-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.options-container {
  overflow-y: auto;
}

.filterable-mat-select .mat-select-panel {
  padding-top: 56px;
}
