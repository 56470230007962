@mixin upsert-exercise-theme($theme) {
  .add-exercise-wrapper {
    .tenant-access-warning-message {
      background-color: map-get($main-theme-color, 100);
    }
    
    .upload-video-wrapper {
      .accepted-formats {
        color: map-get($custom-table-header-color, 500);
      }
      
      .supported-extensions-button {
        color: map-get($main-theme-color, 500);
      }
    }
  }
}
