$orange: #ff6a00;
$light-orange: #ffc19e;
$dark-orange: #c936294b;
$white: #ffffff;
$white-gray: #fafafa;
$red: #ff1702;
$peach: #ff4048;
$yellow: yellow;
$yellow-green: #ccf5b0a6;
$blue: #59b0f3;
$green: green;
$light-green: #8bc34a;

$grey1: #d0d0d0;
$grey2: #7e7e7e;
$grey3: #1e1e23;
$grey4: #f5f5f5;
$grey5: #00000014;

$light-primary-text: $white;

$main-theme-color: (
  50: rgba(255, 106, 0, 0.1),
  100: rgba(255, 106, 0, 0.2),
  200: rgba(255, 106, 0, 0.4),
  300: rgba(255, 106, 0, 0.6),
  400: rgba(255, 106, 0, 0.8),
  500: $orange,
  700: $orange,
  contrast: (
    500: $light-primary-text,
  ),
);

$main-theme-background-color: (
  500: $white,
  contrast: (
    500: $light-primary-text,
  ),
);

$light-main-theme-color: (
  500: $light-orange,
  contrast: (
    500: $light-primary-text,
  ),
);

$custom-table-border-color: (
  500: $grey1,
  contrast: (
    500: $light-primary-text,
  ),
);

$custom-table-header-color: (
  500: $grey2,
  contrast: (
    500: $light-primary-text,
  ),
);

$text-color-against-main-color: (
  500: $white,
  contrast: (
    500: $light-primary-text,
  ),
);

$panel-color: (
  500: $grey3,
  contrast: (
    500: $light-primary-text,
  ),
);

$toolbar-color: (
  500: $grey4,
  contrast: (
    500: $light-primary-text,
  ),
);

$navbar-color: (
  500: $grey4,
  contrast: (
    500: $light-primary-text,
  ),
);

$selector-background: (
  500: #c936294b,
  contrast: (
    500: $light-primary-text,
  ),
);

$error-color: (
  500: $red,
  contrast: (
    500: $light-primary-text,
  ),
);

$input-warning: (
  500: $yellow,
  contrast: (
    500: $light-primary-text,
  ),
);

$input-success: (
  500: $yellow-green,
  contrast: (
    500: $light-primary-text,
  ),
);

$splash-color: (
  500: $white-gray,
  contrast: (
    500: $light-primary-text,
  ),
);

$mark-color: (
  500: $peach,
  contrast: (
    500: $light-primary-text,
  ),
);

$input-background-color: (
  500: $grey5,
  contrast: (
    500: $light-primary-text,
  ),
);

$blue-color-accent: (
  500: $blue,
  contrast: (
    500: $light-primary-text,
  ),
);

$success-color: (
  500: $green,
  contrast: (
    500: $light-primary-text,
  ),
);
