@mixin individual-assessment-overview-theme($theme) {
  
  .assessment-overview-wrapper {
    .buttons-wrapper {
      .change-providers-button {
        color: map-get($main-theme-color, 500);
      }
    }
  }
}
