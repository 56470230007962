@mixin edit-assessment-theme($theme) {

  .spl-edit-assesment {
    .assessment {
      .assesment-config {
        background-color: map-get($toolbar-color, 500);
      }
    }
  }
  
}
