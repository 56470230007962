@use '@angular/material' as mat;
@mixin reset-password-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  reset-password {
    #reset-password {
      background-color: map-get($fuse-white, 500);
      #reset-password-form-wrapper {
        .splash {
          background: url('/assets/images/logos/splash.png') no-repeat center
            map-get($splash-color, 500);
        }

        #reset-password-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .submit-button {
            background-color: map-get($main-theme-color, 500);
            &.mat-button-disabled {
              opacity: 0.3 !important;
            }
          }

          .error-code {
            color: map-get($main-theme-color, 500);
          }
        }
      }
    }
  }
}
