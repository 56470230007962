@mixin assessment-summary-theme($theme) {
  
  .spl-assesment-summary {
    .summary-grid {
      .assessment-no-data {
        color: map-get($error-color, 500);
      }
    }
  }
  
}
