@mixin in-studio-daily-attendance-theme($theme) {
  .spl-page-wrapper {
    .spl-content-host-wrapper {
      .spl-content-host {
        .custom-grid {
          .name-wrapper {
            .check-in {
              color: map-get($success-color, 500);
            }

            .lock {
              color: map-get($main-theme-color, 500);
            }
          }
        }
      }
    }
  }
}
