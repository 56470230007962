@mixin add-new-individual-assessment-theme($theme) {

  .add-new-individual-assessment {
    .assessment-wrapper {
      .assessment-selector {
        border: 1px solid map-get($main-theme-color, 500);

        &.selected {
          background-color: map-get($light-main-theme-color, 500);
        }
      }
    }
  }

}
