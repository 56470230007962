@mixin in-studio-program-theme($theme) {
  .exercises-list {
    background-color: map-get($fuse-white, 500);
    border: 1px solid map-get($custom-table-border-color, 500);

    box-shadow: 2px 2px 3px 0px map-get($input-background-color, 500);
    -webkit-box-shadow: 2px 2px 3px 0px map-get($input-background-color, 500);
    -moz-box-shadow: 2px 2px 3px 0px map-get($input-background-color, 500);
  }
}
