@mixin spl-navigation-theme($theme) {
  spl-navigation {
    color: map-get($fuse-black, 500);

    .nav-link {
      &.accent {
        color: map-get($fuse-white, 500) !important;
      }

      &.accent {
        background-color: map-get($main-theme-color, 500) !important;
        color: map-get($text-color-against-main-color, 500) !important;
      }
    }
  }
}
