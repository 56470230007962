@mixin exercise-program-theme($theme) {
  .spl-page-wrapper {
    .details-toolbar {
      .program-status {
        color: map-get($main-theme-color, 500);
      }
    }

    .weeks-wrapper {
      .week {
        .week-title {
          .title {
            background-color: map-get($fuse-white, 500);
          }
        }
      }
    }
  }
}
