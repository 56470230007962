@mixin select-assessment-type-menu-theme($theme) {



  .selected-assessment-types-wrapper {
    .tests {
      .test {
        border: 1px solid map-get($main-theme-color, 500);

        &.selected {
          background-color: map-get($light-main-theme-color, 500);
        }
      }
    }
  }
}
