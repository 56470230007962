.details-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 25px 0 25px;
  
  .refresh-button {
    cursor: pointer;
    transform: scale(1.2);
    
    &.disabled {
      opacity: 0.7;
    }
  }

  mat-form-field {
    width: 236px;
    margin-right: 16px;
  }
  
  mat-form-field.mat-form-field {
    font-size: 14px !important;
  }
  
  .details-toolbar__left {
    flex: 1 1 auto;
    width: 100%;
  }
  
  .details-toolbar__right {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
  }
}