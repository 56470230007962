//$component-bg: #212121;
//$component-text: rgba(255, 255, 255, 0.7);
//$grid-header-bg: #424242;
//$grid-header-text: #ffffff;
$primary: #ff6a00;
@import '@progress/kendo-theme-default/dist/all.scss';
@import '../assets/styles/bo-orange.theme-colors.scss';

.k-icon {
  font-family: 'WebComponentsIcons', sans-serif !important;
}
.spl-grid {
  // border-color: #832921bb;
  border-radius: 4px;
  overflow: hidden;
  &.spl-height-100 {
    height: 100%;
  }
  .k-icon {
    font-family: 'WebComponentsIcons', sans-serif !important;
  }
  .spl-nowrap-col {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .spl-inline-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin-right: 5px;
    }
  }
  .spl-inline-wrap-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin-bottom: 5px;
      margin-right: 5px;
      height: 30px;
      line-height: 22px;
      min-width: 70px;
    }
  }
  .spl-inline-input {
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    width: 100%;
    border: 1px solid map-get($input-background-color, 500);
  }
  .spl-inline-textarea {
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    width: 100%;
    border: 1px solid map-get($input-background-color, 500);
    resize: none;
    
    &.invalid {
      border-color: map-get($error-color, 500);
    }
  }
  .validation-error {
    color: map-get($error-color, 500);
  }
}

.k-grid .k-grid-filter-menu {
  color: inherit !important;
}

.k-grid .k-grid-header-menu.k-active {
  padding: 2px 4px;
  color: map-get($text-color-against-main-color, 500) !important;
  background-color: map-get($main-theme-color, 500);
}

.all-assessments-grid {
  .k-grid td {
    white-space: nowrap;
  }

  .k-grid tr td {
    height: 39px;
    padding: 10px 10px !important;
    &.k-state-selected {
      background-color: map-get($custom-table-header-color, 500);
    }
  }

  .k-grid th:not(.k-filterable) {
    display: inline-block;
    width: 44px !important;
  }

  .k-grid td:hover {
    background: map-get($custom-table-border-color, 500);
  }

  .k-grid-header .k-header {
    vertical-align: inherit !important;
  }

  .k-grid-content {
    width: max-content !important;
  }
}

.spl-multiselecttree-item {
  position: relative;
  .spl-undefined-check {
    width: 8px;
    height: 3px;
    border-bottom: 2px solid map-get($main-theme-color, 500);
    top: 10px;
    left: -24px;
    position: absolute;
  }
}

.spl-assessment-overview {
  .k-grid td {
    vertical-align: top;
  }
}

@mixin kendo-theme($theme) {
  //.k-grid-pager {
  //  color: map-get($spl-light-gray, 500) !important;
  //}
  //
  //.k-pager-numbers .k-link {
  //  color: map-get($spl-light-gray, 500) !important;
  //}
  //
  //.k-pager-refresh:hover,
  //.k-pager-nav.k-link:hover,
  //.k-state-hover.k-pager-refresh,
  //.k-state-hover.k-pager-nav.k-link,
  //.k-hover.k-pager-refresh,
  //.k-hover.k-pager-nav.k-link {
  //  color: map-get($spl-light-gray, 500) !important;
  //}
  //
  //.k-pager-numbers .k-link.k-state-selected,
  //.k-pager-numbers .k-link.k-selected {
  //  color: map-get($spl-light-gray, 500) !important;
  //}
}
