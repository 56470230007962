// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------

@import '../assets/styles/bo-orange.theme-colors.scss';

$fuse-white: (
  100: white,
  500: white,
  700: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$fuse-black: (
  100: black,
  500: black,
  700: black,
  contrast: (
    500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #000000,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
  main-theme-color: $main-theme-color,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-legacy-typography-config(
  $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
  $title: mat.define-typography-level(20px, 32px, 600),
  $body-2: mat.define-typography-level(14px, 24px, 600),
  $button: mat.define-typography-level(14px, 14px, 600),
  $input: mat.define-typography-level(16px, 1.125, 400),
);

// Setup the typography
@include mat.all-legacy-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import common styles
@import 'src/assets/styles/details-toolbar';

// Import app and page component themes here to enable theming for them
@import 'src/app/auth/components/login/login.theme';
@import 'src/app/auth/components/code-entry/code-entry.theme';
@import 'src/app/auth/components/two-factor-code/two-factor-code.theme';
@import 'src/app/auth/components/forgot-password/forgot-password.theme';
@import 'src/app/auth/components/reset-password/reset-password.theme';
@import 'src/app/layout/bo-app-layout/components/navbar/navbar.theme';
@import 'src/app/layout/bo-app-layout/components/footer/footer.theme';
@import 'src/app/kendo.theme';
@import 'src/assets/styles/details-toolbar.theme';
@import 'src/app/core/components/navigation/navigation.theme';
@import 'src/app/assessments/assesment-details/assessment-details.theme';
@import 'src/app/assessments/assessment-summary/assessment-summary.theme';
@import 'src/app/layout/bo-app-layout/components/toolbar/toolbar.theme';
@import 'src/app/assessments/assessment-all/assessment-all.theme';
@import 'src/app/dashboards/dashboards.theme';
@import 'src/app/out-of-studio-trainings/components/training-set-history/training-set-history.theme';
@import 'src/app/personal-record-assessments/components/add-individual-assessment/add-new-individual-assessment/add-new-individual-assessment.theme';
@import 'src/app/personal-record-assessments/components/personal-record-assessment/personal-record-assessment.theme';
@import 'src/app/personal-record-assessments/components/select-assessment-type-menu/select-assessment-type-menu.theme';
@import 'src/app/personal-records/components/personal-records-by-insurance/personal-records-by-insurance.theme';
@import 'src/app/personal-records/components/training-history/training-history.theme';
@import 'src/app/assessments/edit-assessment/edit-assessment.theme';
@import 'src/app/assessment-results/components/provider-assignment/provider-assignment.theme';
@import 'src/app/assessments/status-label/status-label.theme';
@import 'src/app/assessments/assessment-all/assessment-detail-card/assessment-detail-card.theme';
@import 'src/app/exercise-programs/components/upsert-program/upsert-program.theme';
@import 'src/app/personal-records/components/in-studio-program/in-studio-program.theme';
@import 'src/app/in-studio-trainings/components/in-studio-daily-attendance/in-studio-daily-attendance.theme';
@import 'src/app/personal-records/components/exercise-program/exercise-program.theme';
@import 'src/app/features/exercise-program-weeks-overview/exercise-program-weeks-overview.theme';
@import 'src/app/exercise-programs/components/upsert-program/components/custom-program-exercise/custom-program-exercise.theme';
@import 'src/app/exercise-programs/components/exercise-program-individual-edit/exercise-program-individual-edit.theme';
@import 'src/app/exercise-programs/components/exercise-program-individual-edit/components/individual-edit-exercise/individual-edit-exercise.theme';
@import 'src/app/training-exercises/components/upsert-exercise/upsert-exercise.theme';
@import 'src/app/personal-record-assessments/components/assessment-overview/assessment-overview.theme';
@import 'src/app/features/service-is-unavailable-message/service-is-unavailable-message.theme';
@import 'src/app/bitmovin-player/bitmovin-player.theme';

@import 'src/app/core/components/notification/notification.theme';

// Import from common library
@import '../../../../../libs/common/src/shared/components/overlay-loader/overlay-loader.theme';
@import '../../../../../libs/common/src/shared/components/navigation-vertical-menu/navigation-vertical-menu.theme';
@import '../../../../../libs/common/src/shared/components/files-uploader/files-uploader.theme';
@import '../../../../../libs/common/src/shared/components/confirm-changes-dialog/confirm-changes.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Pages
  @include login-theme($theme);
  @include code-entry-theme($theme);
  @include two-factor-code-theme($theme);
  @include forgot-password-theme($theme);
  @include reset-password-theme($theme);
  @include navbar-theme($theme);
  @include footer-theme($theme);
  @include kendo-theme($theme);
  @include details-toolbar-theme($theme);
  @include spl-navigation-theme($theme);
  @include toolbar-theme($theme);
  @include assessment-details-theme($theme);
  @include assessment-summary-theme($theme);
  @include assessment-all-theme($theme);
  @include dashboards-theme($theme);
  @include training-set-history-theme($theme);
  @include files-uploader-theme($theme);
  @include add-new-individual-assessment-theme($theme);
  @include personal-record-assessment-theme($theme);
  @include select-assessment-type-menu-theme($theme);
  @include personal-records-by-insurance-theme($theme);
  @include training-history-theme($theme);
  @include edit-assessment-theme($theme);
  @include provider-assignment-theme($theme);
  @include status-label-theme($theme);
  @include assessment-detail-card-theme($theme);
  @include upsert-program-theme($theme);
  @include in-studio-program-theme($theme);
  @include in-studio-daily-attendance-theme($theme);
  @include exercise-program-theme($theme);
  @include exercise-program-weeks-overview-theme($theme);
  @include custom-program-exercise-theme($theme);
  @include exercise-program-individual-edit($theme);
  @include individual-edit-exercise-theme($theme);
  @include upsert-exercise-theme($theme);
  @include individual-assessment-overview-theme($theme);
  @include confirm-changes-theme($theme);
  @include service-is-unavailable-message-theme($theme);
  @include bitmovin-player-theme($theme);

  // UI
  @include overlay-loader-theme($theme);
  @include navigation-vertical-menu-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-black);
$default-accent-palette: mat.define-palette($main-theme-color);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// @TODO Angular Material themes should be created from a map containing the keys "color", "typography", and "density". The color value should be a map containing the palette values for "primary", "accent", and "warn".
// Create the Material theme object
$theme: mat.define-light-theme(
  $default-primary-palette,
  $default-accent-palette,
  $default-warn-palette
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-legacy-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette(
  $fuse-navy,
  600,
  400,
  700
);
$yellow-light-theme-accent-palette: mat.define-palette(
  mat.$yellow-palette,
  600,
  400,
  700
);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme(
  $yellow-light-theme-primary-palette,
  $yellow-light-theme-accent-palette,
  $yellow-light-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-themes($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(
  mat.$blue-gray-palette
);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme(
  $blue-gray-dark-theme-primary-palette,
  $blue-gray-dark-theme-accent-palette,
  $blue-gray-dark-theme-warn-palette
);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-themes($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a black dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$black-dark-theme-primary-palette: mat.define-palette($fuse-white);
$black-dark-theme-accent-palette: mat.define-palette($fuse-white);
$black-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$black-dark-theme: mat.define-dark-theme(
  $black-dark-theme-primary-palette,
  $black-dark-theme-accent-palette,
  $black-dark-theme-warn-palette
);

// Add ".theme-black-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-black-dark {
  background: black !important;
  // Generate the Angular Material theme
  @include mat.all-legacy-component-themes($black-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($black-dark-theme);

  // Apply the theme to the user components
  @include components-theme($black-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $black-dark-theme-primary-palette,
    accent: $black-dark-theme-accent-palette,
    warn: $black-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme(
  $pink-dark-theme-primary-palette,
  $pink-dark-theme-accent-palette,
  $pink-dark-theme-warn-palette
);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-themes($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}
