@mixin individual-edit-exercise-theme($theme) {
  .exercise-wrapper {
    input {
      border: 1px solid map-get($custom-table-border-color, 500);
    }

    select {
      border: 1px solid map-get($custom-table-border-color, 500);
    }

    .input-error {
      color: map-get($error-color, 500);
    }
  }
}
