@use '@angular/material' as mat;
@mixin two-factor-code-theme($theme) {
  .two-factor-code-wrapper {
    .submit-button {
      background-color: map-get($main-theme-color, 500) !important;
    }

    .error-text {
      color: map-get($error-color, 500) !important;
    }

    .new-code-button {
      color: map-get($main-theme-color, 500) !important;
    }

    .back-action {
      color: map-get($main-theme-color, 500);
    }
  }
}
