@mixin service-is-unavailable-message-theme($theme) {
  .service-is-unavailable-message-wrapper {
    background-color: rgba(239, 239, 240, 0.5);
    
    .message-wrapper {
      
      .link-to-login {
        color: map-get($main-theme-color, 500);
      }
    }
  }
}
