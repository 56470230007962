@mixin personal-record-assessment-theme($theme) {

  .spl-content-host {
    .tooltip-error {
      background: map-get($error-color, 500);
    }
    .details-grid__field {
      &.processing-error {
        .details-grid__input {
          background-color: map-get($input-warning, 500);
        }
      }
      &.processing-success {
        .details-grid__input {
          background-color: map-get($input-success, 500);
        }
      }

      .details-grid__suboptimal-badge {
        background-color: map-get($mark-color, 500);
        box-shadow: 0px 1px 3px 1px map-get($input-background-color, 500);
      }
    }
    .details-grid__input-errors {
      color: map-get($error-color, 500);
    }
    .details-grid__input {
      border: 1px solid map-get($input-background-color, 500);
    }
  }
  .details-grid__stats {
    .details-grid__stat {
      border: 1px solid map-get($error-color, 500);
    }
  }
}
