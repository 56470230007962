@mixin dashboards-theme($theme) {
  
  .score-wrapper {
    border: 1px solid map-get($custom-table-border-color, 500);

    .score-value {
      color: map-get($main-theme-color, 500);
    }
  }
  
  .dashboards-wrapper {
    .details-toolbar {
      .details-toolbar__left {
        .dashboards-type {
          border: 2px solid map-get($main-theme-color, 500);
          background-color: map-get($fuse-white, 500);
          
          &.selected {
            background-color: map-get($main-theme-color, 500);
            color: map-get($text-color-against-main-color, 500);
          }
        }
      }
    }
  }
}
