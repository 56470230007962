@mixin navigation-vertical-menu-theme($theme) {
  .custom-tabs-wrapper {
    .tab {
      color: map-get($fuse-black, 500);

      &.selected {
        color: map-get($text-color-against-main-color, 500);
        background-color: map-get($main-theme-color, 500);
      }
    }
    .tab:not(.selected):hover {
      background-color: map-get($splash-color, 500);
    }
  }
}
