@mixin upsert-program-theme($theme) {
  .spl-page-wrapper {
    .details-toolbar {
      .status {
        color: map-get($main-theme-color, 500);
      }

      .details-toolbar__right {
        .fill-weeks-error {
          color: map-get($error-color, 500);
        }
      }
    }
    .spl-content-host-wrapper {
      .spl-content-host {
        .weeks-panel {
          .weeks {
            .week {
              border: 1px solid map-get($custom-table-header-color, 500);

              &.selected {
                background-color: map-get($main-theme-color, 500);
                color: map-get($text-color-against-main-color, 500);
              }

              &.no-exercises-error {
                border: 2px solid map-get($error-color, 500);
              }
            }
          }
        }
      }


      .buttons-wrapper {
        background-color: map-get($fuse-white, 500);
      }

      .custom-program-overlay {
        background-color: map-get($splash-color, 500);
      }

      .custom-program-day-wrapper {

        .title-wrapper {
          background-color: map-get($fuse-white, 500);
        }
      }
    }
  }

  .tags-list {
    background-color: map-get($fuse-white, 500);
    border: 1px solid map-get($custom-table-border-color, 500);
  }
}
